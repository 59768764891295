import { upload, getCompanyId } from "../../../Auth";

const getDefaultFields = (vehicle, page, limit, matchingFilters, station) => {
    const ret = {
        get: "vehicleImages",
        functionName: "getVehicleImages",
        criteria: matchingFilters,
        excludes: {
            0: "company",
            1: "skill",
        },
        includes: {
            0: "id",
            1: "label",
            2: "path",
            3: "createdAt",
            4: "isArchive",
            5: "inspectionImagePath",
            6: "inspectionFailedNote",
            category: ["id", "name"],
            updatedBy: ["friendlyName"],
            vehicle: {
            //     station: {
                0: "vehicleId",
            //     },
            },
        },
        // orderBy: {
        //     createdAt: "desc",
        // },
        // matching: matchingFilters,
        // page: page,
        // limit: limit,
    };

    // if (vehicle) {
    //     ret["criteria"]["vehicle"] = vehicle;
    // }

    return ret;
};

const fetchVehicleImage = async (api, vehicle, page, limit, state, driverId, timeframe, station, category, label, inspectionFailedNote) => {
    let matchingFilters = {
        vehicleId: (vehicle != 'all' && typeof vehicle === "object" && vehicle?.id != "all") ? vehicle : '',
        "stationId": station, 'tags': (category != 'all') ? category : '', 'label': label, 'userId': (driverId != 'all') ? driverId : '',
        'page': page, 'limit': limit, 'inspectionFailedNote': (inspectionFailedNote != 'all') ? inspectionFailedNote.map(note => state.vehicleImageAiCategory
            .find(item => item.value === note)?.name || '') : ''
    };
    if (timeframe){
        matchingFilters.startDate=timeframe.start;
        matchingFilters.endDate =timeframe.end;
    }

    const findCriteria = getDefaultFields(vehicle, page, limit, matchingFilters, station);

    // if (state.filter.search) {
    //     findCriteria.matching = { label: { contains: state.filter.search } };
    //     if (state.list.length < 16) {
    //         findCriteria.page = 1;
    //         state.list = [...[]];
    //     }
    // }

    const params = {
        actions: {
            response: {
                VehicleImage: {
                    custom: findCriteria,
                },
            },
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);
        return response.data;
    } catch (error) {}
};

const handlePrintImageVehicle = async (api, selectedImageArr, selectedVehicle) => {
    const params = {
        actions: {
            response: {
                VehicleImage: {
                    custom: {
                        get: "handlePrintImageVehicle",
                        functionName: "handlePrintImageVehicle",
                        criteria: {
                            selectedImageArr:selectedImageArr,
                            selectedVehicle:selectedVehicle
                        }
                    },
                },
            },
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);
        return response.data;
    } catch (error) {}
};

const fetchVehicleDetail = async (api, id) => {
    if (!id)
        return null;

    const params = {
        actions: {
            response: {
                Vehicle: {
                    findOneBy: {
                        criteria: {
                            id: id,
                            isArchive: false,
                            company: getCompanyId(),
                        },
                        get: "Vehicle",
                        includes: {
                            0: "id",
                            1: "vehicleId",
                        },
                    },
                },
            },
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);
        return response.data.data.Vehicle;
    } catch (error) {}
};

const fetchDriversData = async (api, station) => {

    const driverParams = {
        actions: {
            response: {
                Driver: {
                    custom: {
                        get: "drivers",
                        functionName: "getDriversList",
                        criteria: {
                            station: station,
                            company: getCompanyId(),
                        },
                    },
                },
            },
        },
    };

    try {
        const [driverPromise] = await Promise.allSettled([
            api.post("/api/lazy/manage/data", driverParams),
        ])
        const Driver = driverPromise['value'];

        return {
            drivers: Driver?.data?.data?.drivers || [],
        };
    } catch (error) { }
};

const fetchVehiclesData = async (api, station) => {
    const vehicleParams = {
        actions: {
            response: {
                Vehicle: {
                    findBy: {
                        criteria: {
                            isArchive: false,
                            company: getCompanyId(),
                            station: station,
                        },
                        get: "Vehicle",
                        includes: {
                            0: "id",
                            1: "vehicleId",
                        },
                    },
                }
            },
        },
    };

    try {
        const [vehiclesPromise] = await Promise.allSettled([
            api.post("/api/lazy/manage/data", vehicleParams)
        ])

        const Vehicle = vehiclesPromise['value'];

        return {
            Vehicle: Vehicle?.data?.data?.Vehicle || [],
        };
    } catch (error) { }
};

const fetchVehicleImageCategory = async (api, vehicle) => {
    const params = {
        actions: {
            response: {
                VehicleImage: {
                    custom: {
                        functionName: "getImageCategory",
                        get: "Categories",
                        criteria: {
                            companyId: getCompanyId()
                        }
                    }
                },
            },
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);
        return response.data.data.Categories;
    } catch (error) {}
};

const fetchAiImageCategory = async (api) => {
    const params = {
        actions: {
            response: {
                VehicleImage: {
                    custom: {
                        functionName: "getAiImageCategory",
                        get: "vehicleImages",
                        criteria: {
                            companyId: getCompanyId(),
                        }
                    }
                }
            }
        },
    };

    try {
        const response = await api.post("/api/lazy/manage/data", params);
        return response.data.data.vehicleImages;
    } catch (error) { }
}

const AddVehicleImage = async (api, data, files, vehicle) => {
    let params;
    let formData = new FormData();
    files.map(async (file, i) => {
        formData.append("image[]", file);
    });

    try{
        let result = await upload(formData).catch((err) => {
            console.log({ err });
        });
        let paramsData = {};
        if(result.data.count === 1){
            await result.data.data.map((vi,i)=>{
                let key = "VehicleImage_" + i;
                let fileUrl = vi.ObjectURL;
                paramsData[key]={
                    category: data.category,
                    label: data.label,
                    path: fileUrl,
                    vehicle: vehicle,
                };
            });
        }else{
            await result.data.map((vi,i)=>{
                let key = "VehicleImage_" + i;
                let fileUrl = vi.ObjectURL;
                paramsData[key]={
                    category: data.category,
                    label: data.label,
                    path: fileUrl,
                    vehicle: vehicle,
                };
            });
        }
        return paramsData;
    } catch (error) {}

};

const deleteVehicleImage = async (api, id) => {
    const params = {
        actions: {
            update: {
                VehicleImage: {
                    VehicleImage: {
                        findBy: {
                            id: id,
                        },
                        updateRecord: {
                            isArchive: true,
                        },
                    },
                },
            },
        },
    };

    try {
        await api.post("/api/lazy/manage/data", params);
        return true;
    } catch (error) {
        await alert("Error occurred");
    }
};

export {
    AddVehicleImage,
    fetchVehicleImage,
    deleteVehicleImage,
    fetchVehicleImageCategory,
    fetchVehicleDetail,
    handlePrintImageVehicle,
    fetchAiImageCategory,
    fetchDriversData,
    fetchVehiclesData
};
