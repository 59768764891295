export const STATUS = {
    PENDING: 'pending',
    READY: 'Ready',
    ERROR: 'error',
    FILTERING: 'filtering'
}


export const initialState = (options) => {
    return {
        matching: options.matching || {},
        filterLookups: options.filterLookups || [],
        status: STATUS.PENDING,
        data: [],
        refreshCount: 0
    }
}

const handleWaveTimeStringify = (waveTime) => {
    if (typeof waveTime === 'string') {
        try {
            const parsedWaveTime = JSON.parse(waveTime);
            return parsedWaveTime;
        } catch (error) {
            return []
        }
    } else {
        return waveTime
    }
}

export const reducer = (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case 'loading':
            return { ...state, status: payload.loading }
        case 'addData':
            const newData = payload.data || [];
            state.data = newData.map((data) => {
                data.waveTime = data.waveTime.filter((w) => w !== null && typeof waveTime === 'string')
                return data;
            });
            // .filter((t, k) => {
            //     t.waveTime = handleWaveTimeStringify(t.waveTime);
            //     return t.waveTime?.length > 0 && t.waveTime?.[0];
            // })
            state.status = STATUS.READY;
            return { ...state };
        case 'createData':
            if (payload.data) {
                payload.data.isNew = true;
                state.data.push(payload.data);
            }
            return { ...state };
        case 'removeMultipleData':
            payload.id.forEach((id) => {
                const removeIndex = state.data.findIndex((s) => s.id === id);
                if (removeIndex >= 0) {
                    state.data.splice(removeIndex, 1);
                }
            });
            return { ...state };
        case 'editData':
            const { id } = payload.data;
            const editIndex = state.data.findIndex((s) => s.id === id);
            if (editIndex >= 0) {
                payload.data.isNew = true;
                payload.data.waveTime = handleWaveTimeStringify(payload.data.waveTime)
                state.data[editIndex] = payload.data;
            }
            return { ...state };
        case 'forceRefresh':
            state.refreshCount += 1;
            state.status = STATUS.PENDING;
            return { ...state };
        default:
            return { ...state };
    }
}