import React from "react";
import { confirm as confirmPortal } from "../../../../components/Confirm";
import DayPicker from '../../../Calendar/day';
import moment from "moment";
import { Spacer } from "@dspworkplace/ui";


const DatePickerInput = (props) => {
    const {row, column, onClose, onRowChange} = props

    const dateChangeHandler = async (date) => {
        const result = await confirmPortal(`Do want to assign "${column.name}" to "${date.original}" on the ${row.id}?`)
        if(result){
            onRowChange({ ...row, [column.key]: moment.utc(date.original).format('YYYY-MM-DD')}, true)
        }
        onClose();
        return;
       
    }

    return (
        <Spacer style={{lineHeight:'20px', paddingTop:'3px'}}>
            <DayPicker
                defaultValue={ row[column.key] ? moment.utc(row[column.key]).format('YYYY-MM-DD') : null}
                onChange={dateChangeHandler}
                size='small'
                defaultOpen={true}
            />
        </Spacer>
    )
}
export default DatePickerInput