import {EditorProps} from "react-data-grid";
import {Combobox} from "../../../Form/Combobox";
import React from "react";
import { OptionRenderProps } from "../../../Form/Combobox/combobox";
import { confirm as confirmPortal, confirmOption } from "../../../../components/Confirm";
import { openPlanForm } from "../../../../pages/Vehicles/VehicleManagement/PlanForm";
import {useAuth} from "../../../../Auth";
import { openCompleteTaskFrom } from "../../../../pages/Vehicles/VehicleManagement/completeTaskFrom";
import {toast} from '../../../../components/Toasts';
interface BaseProps extends EditorProps<any>{
    OptionRender?: OptionRenderProps
}

const EditorComboboxBase = ({row,column,onClose, onRowChange, OptionRender}:BaseProps) => {
    const { api } = useAuth();

    return <Combobox
        size={'full'}
        optionStyle={{maxHeight:'100px'}}
        // @ts-ignore
        options={column?.validValues || []}
        OptionRender={OptionRender}
        // @ts-ignore
        initialValue={row[column.key]}
        startOpen={true}
        onChange={async (opt)=>{
            if(row?.category === null) {
                toast({
                    type: "error",
                    title: `Can't Change Task Status`,
                    content: `First select category.`
                });
                return false
            }
            let result = false;
            if(column.name == "Ticket Status" && column.key == 'status' && opt.value == 'pending') {
                toast({
                    type: "error",
                    title: `Can't Change Task Status`,
                    content: `You can not change Task Status as Pending.`
                });
            } else {
               result = await confirmPortal(`Do want to assign "${column.name}" to "${opt.value}" on the ${row.id}?`)
            }
            if (!result) {
                onRowChange({...row},true);
                onClose();
                return false;
            }
            if (column.name == "Ticket Status" && column.key == 'status' && opt.value == 'complete') {
                // const completeTaskFormData = await openCompleteTaskFrom();
                // console.log(row);
                
                // if(completeTaskFormData){
                //     await api.post("/api/lazy/manage/data",{
                //         actions: {
                //             update: {
                //                 "Vehicle\\VehicleMaintenanceTask": {
                //                     task_1: {
                //                         findBy:{
                //                             id:row.id
                //                         },
                //                         updateRecord:{
                //                             dateServiced:new Date(completeTaskFormData.dateServiced)
                //                         }
                //                     }
                //                 }
                //             }
                //         }
                //     }).catch(e => {
                //         console.log(e);
                //         return false;
                //     });
                // }else{
                //     return false;
                // }
                if (row.planItem) {
                    let currentMileage = row.vehicle.currentMileage;
                    
                    let formResult = null;
                    if (!row.category.isLastMileServiceRequired && !row.category.isLastServiceDateRequired) {
                        formResult = [{ item_id: row.planItem.id }]
                    } else {
                        formResult = await openPlanForm({ planItems: [{ id: row.planItem.id, category: row.category }] });
                    }
                    if (formResult) {
                        let createNewTaskResponse = null;
                        let params = {
                            completeMessage: 'Confirm "Yes" for create a new task'
                        }
                        if (formResult.length > 0 && formResult[0].date) {
                            params.dateServiced = new Date(formResult[0].date);
                        }

                        await api.post("/api/lazy/manage/data", {
                            actions: {
                                update: {
                                    "Vehicle\\VehicleMaintenanceTask": {
                                        task_1: {
                                            findBy: {
                                                id: row.id
                                            },
                                            updateRecord: params
                                        }
                                    }
                                }
                            }
                        }).catch(e => {
                            console.log(e);
                        });

                        let plan_items = formResult.map(service => {
                            if(currentMileage <= parseFloat(service.miles)){
                                currentMileage = service.miles;
                            }
                            return ({
                                mileage: parseFloat(service.miles),
                                item_id: parseInt(service.item_id),
                                date: service.date
                            })
                        })

                        if (row.vehicle.currentMileage <= parseFloat(currentMileage)) {
                            await api.post("/api/lazy/manage/data", {
                                actions: {
                                    update: {
                                        "Vehicle": {
                                            task_1: {
                                                findBy: {
                                                    id: row.vehicle.id
                                                },
                                                updateRecord: {
                                                    currentMileage: currentMileage
                                                }
                                            }
                                        }
                                    }
                                }
                            });
                        }

                        await api.post("api/v2/vehicle/maintenance/task/addServiceRecords", { plan_items: plan_items, vehicleID: row.vehicle.id });

                        await api.post("api/v2/vehicle/maintenance/task/createTask", { id: row.id, vehicleID: row.vehicle.id }).then(async (res) => {
                            createNewTaskResponse = res;
                        });

                        if (createNewTaskResponse) {
                            onRowChange({ ...row, [column.key]: opt.value, res: createNewTaskResponse }, true);
                        } else {
                            onRowChange({ ...row, [column.key]: opt.value }, true);
                        }  
                        onClose();
                    } else {
                        onClose(false);
                    }
                    return true;
                } else {
                    let formResult = await openPlanForm({ planItems: [{ id: row.id, category: row.category }], isValidation: false });
                    let plan_items = formResult.map(service => ({
                        mileage: parseFloat(service.miles),
                        item_id: parseInt(service.item_id),
                        date: service.date
                    }))
                    await api.post("api/v2/vehicle/maintenance/task/addServiceRecords", { plan_items: plan_items, vehicleID: row.vehicle.id });
                }
            }
            const key = column.key.split('.');
            if(key[1]){
                    row[key[0]][key[1]] = opt.value;
            }else{
                row[column.key] = opt.value;
            }
            onRowChange({...row},true);
            onClose();
        }}
    />
}

export default EditorComboboxBase