import React, { useCallback, useEffect, useState } from 'react'
import { BotButton } from '../ChatSidebar'
import { useUpdate as useBotDispatch } from './Store';
import { engine, showErrorToast } from '../../Utilities';
import Loading from '../../Chat/Loading';
import { completeTask, deleteTwilioMessage } from '../utils';
import BotMessage from './BotMessage';
import { Text } from '../styles';
import Linkify from "react-linkify-always-blank";
import ModalWindow from "../../Chat/Modal";
import styled from "styled-components";
import { Icon } from "@dspworkplace/ui";
import parseHTML from "html-react-parser";
import { useTrackedState } from "../store";

const Container = styled.div`
    display: grid;
    grid-template-columns: ${props => (props.simpleList ? "30px 244px 244px 40px 120px" : (props.noOption ? "30px 168px 244px 40px 120px" : "30px 160px 220px 40px 120px 32px"))};
    grid-column-gap: 8px;
    border-bottom: 1px dashed #7c98b6;
    margin-bottom: 2px;
    align-content: center;
    align-items: center;
    padding: 0 0 2px 4px;

    div.punchIn {
        width: 30px;
        height: 30px;
        display: grid;
        align-items: center;
    }
`;

const DriverImage = styled.div`
    display: flex;
    width: 24px;
    height: 24px;
    border: 1px solid #516f90;
    border-radius: 50%;
    cursor: pointer;
    justify-items: center;
    align-items: center;
    flex-direction: column;

    svg {
        display: flex;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #0071bc;
    }

    img {
        display: flex;
        width: 23px;
        height: 23px;
        border-radius: 50%;
    }
`;

const NameAndRole = styled.div`
    display: grid;
    grid-template-columns: auto;
    grid-template-rows: auto auto;
    grid-row-gap: 0;
    align-content: center;
    align-items: center;
    font-size: 12px;
    padding: 2px 0;

    div.name {
        font-size: 14px;
    }

    div.role {
        color: #707070;
        font-size: 8px;
    }
`;

function BotTaskButtons({ reviewHandler, message, channel }) {
    const { attributes, created_at, status, text, user } = message;
    const [showReview, setShowReview] = useState(false);
    const [showBadWordsType, setShowBadWordsType] = useState(false);
    const [showNotificationType, setShowNotificationType] = useState(false);
    const [showIncidentForm, setShowIncidentForm] = useState(false);
    const [showUnknownNotificationType, setShowUnknownNotificationType] = useState(false);
    const [showBadMessage, setShowBadMessage] = useState(false);
    const [loading, setLoading] = useState(true);
    const [task, setTask] = useState();
    const dispatch = useBotDispatch();
    const [isPopup, setIsPopup] = useState(false);
    const [popupText, setPopupText] = useState("");
    const [driverList, setDriverList] = useState([]);
    const [showPopup, setShowPopup] = useState(false);
    const [driverString, setDriverString] = useState("");
    const state = useTrackedState();
    const { filter } = state

    useEffect(() => {
        setLoading(true);
        const popupFindStart = text.indexOf('[popupstart]');
        if (popupFindStart !== -1) {
            setIsPopup(true);
            const popupFindEnd = text.indexOf('[popupend]');
            const popupClickStartIndex = text.indexOf('[popupclickstart]');
            const popupClickEndIndex = text.indexOf('[popupclickend]');
            let tempDriverString = text.slice(popupFindStart + 12, popupFindEnd);
            setDriverString(tempDriverString);

            const messageStartText = text.slice(0, popupClickStartIndex);
            const messageButtonText = text.slice(popupClickStartIndex + 17, popupClickEndIndex);
            const messageEndText = text.replace('[popupstart]' + tempDriverString + '[popupend]', '').slice(popupClickEndIndex + 15, text.length);

            setPopupText(<div>{messageStartText} <span style={{ cursor: 'pointer' }} ><b>{messageButtonText}</b></span> {messageEndText}</div>);
        }

        let taskData;
        if (attributes) {
            if (attributes["task"]) {
                if (attributes['task'] && attributes['task']['type']) {
                    taskData = attributes["task"];
                    setTask(taskData);

                    if (taskData["type"] === "BAD_WORDS") {
                        setShowBadWordsType(true);
                    } else if (taskData["type"] === "NOTIFICATION") {
                        setShowNotificationType(true);
                    } else if (taskData["type"] === "HR_INCIDENT_FORM") {
                        setShowIncidentForm(true);
                    } else {
                        setShowReview(true);
                    }
                }
            } else if (attributes['id'] && attributes['type']) {
                taskData = attributes;
                setTask(taskData);
                if (taskData["type"] === "BAD_WORDS") {
                    setShowBadWordsType(true);
                } else if (taskData["type"] === "NOTIFICATION") {
                    setShowNotificationType(true);
                }
            } else if (attributes['companyId']) {
                setShowUnknownNotificationType(true);
            }
        } else {
            setShowUnknownNotificationType(true);
        }
        setLoading(false);
    }, [message,]);

    useEffect(() => {
        if (task) {
            if (task['type'] === 'HR_INCIDENT_FORM' && !task.hrFormIncidentId)
                getHrFormIncident(task.id).then(res => {
                    const newTask = { ...task, hrFormIncidentId: res };
                    setTask(newTask);
                })
        }
    }, [showIncidentForm]);

    const cancelModal = () => {
        setShowPopup(false);
    }

    const getDriverList = async () => {
        setShowPopup(true);
        let driverIds = driverString.toString().split(',');
        if (driverIds.length > 0) {
            if (isNaN(driverIds[0])) {
                setDriverList(driverIds);
            } else {
                await engine().post("/api/lazy/manage/data", {
                    actions: {
                        response: {
                            "Driver": {
                                custom: {
                                    get: "driver",
                                    functionName: "getDriverByIdForBotNotification",
                                    criteria: {
                                        driverIds: driverIds
                                    },
                                    includes: {
                                        0: "id",
                                        1: "friendlyName",
                                        2: "profileImage",
                                        3: "role"
                                    }
                                }
                            }
                        }
                    }
                }).then(
                    response => {
                        setDriverList(response.data.data.driver);
                        setShowPopup(true);
                    }
                );
            }
        }
    };

    const getHrFormIncident = async (taskId) => {
        const params = {
            actions: {
                response: {
                    Task: {
                        custom: {
                            functionName: "findTask",
                            get: "task",
                            criteria: {
                                taskId: taskId
                            },
                            includes: {
                                0: "id",
                                hrFormIncident: {
                                    0: "id"
                                }
                            }
                        }
                    }
                },
            },
        };
        try {
            setLoading(true);
            const response = await engine().post('/api/lazy/manage/data', params);
            return response.data.data.task.hrFormIncident.id;
        } catch (e) {
            await showErrorToast(e, 'Error deleting message.');
        } finally {
            setLoading(false);
        }
    };

    const deleteMessage = useCallback(
        async () => {
            setLoading(true);
            try {
                await deleteTwilioMessage(channel.id, message.id, attributes);
            } catch (e) {
                await showErrorToast(e, 'Error deleting message.');
            }
            setLoading(false);
        },
        [attributes, dispatch, message],
    );

    const hrIncidentFormClickHandler = async () => {
        if (task) {
            if (task.hrFormIncidentId) {
                window.open(`/incidents/${task.hrFormIncidentId}`, '_blank');
            } else {
                await showErrorToast("incident not founded.", 'Error deleting message.');
            }
        }
    }

    const deleteMessageAndCompleteTask = useCallback(
        async () => {
            setLoading(true);
            try {
                if (task && task['type'] === 'BAD_WORDS') {
                    let result = await completeTask(task, message, channel);
                    if (result) {
                        dispatch({ type: "MESSAGE_REMOVED", sid: message.id });
                    }
                } else if (task && task['type'] === 'NOTIFICATION') {
                    let result = await completeTask(task, message, channel);
                    if (result) {
                        dispatch({ type: "MESSAGE_REMOVED", sid: message.id });
                    }
                } else if (task && task['type'] === 'HR_INCIDENT_FORM') {
                    let result = await completeTask(task, message, channel);
                    if (result) {
                        dispatch({ type: "MESSAGE_REMOVED", sid: message.id });
                    }
                } else {
                    throw new Error('No type for Task is defined.' + JSON.stringify(task));
                }
            } catch (e) {
                await showErrorToast(e, 'Error deleting message.');
            }
            setLoading(false);
        },
        [attributes, dispatch, message, task],
    );

    const showBadMessageHandler = useCallback(
        () => {
            setShowBadMessage(!showBadMessage);
        },
        [showBadMessage],
    );
    
    const badWordsHighlight = (contentText = '') => {

        const badWordsObject = JSON.parse(localStorage.getItem("badWordsObject"));
        const wordsArray     = badWordsObject.words;
    
        const unfilteredMatch = contentText.match(/Unfiltered content:([\s\S]*?)Filtered content:/);
        const filteredMatch   = contentText.match(/Filtered content:([\s\S]*)/);

        const unfilteredContent = unfilteredMatch ? unfilteredMatch[1].trim() : "";
        const filteredContent   = filteredMatch ? filteredMatch[1].trim() : "";
        
        const escapeRegExp = (str) => str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
        const regex = new RegExp(`\\b(${wordsArray.map(escapeRegExp).join("|")})\\b`, "gi");

        const unfilteredContentHtml = unfilteredContent.replace(regex,(match) => `<span style="background-color: red; color: #000000; padding:2px;">${match}</span>`);
        
        const html = `
              <div style="font-family: Arial, sans-serif; line-height: 1.6;">
                  <h3 style="color: #000000; border-bottom: 2px solid #555; padding-bottom: 5px; margin-bottom: 10px;">Unfiltered Content</h3>
                  <div style="padding: 10px; background-color: #f9f9f9; border: 1px solid #ddd; border-radius: 5px; text-align: justify;">
                      ${unfilteredContentHtml}
                  </div>
                  <h3 style="color: #000000; border-bottom: 2px solid #555; padding-bottom: 5px; margin-top: 20px; margin-bottom: 10px;">Filtered Content</h3>
                  <div style="padding: 10px; background-color: #fff4e6; border: 1px solid #ffcc99; border-radius: 5px; text-align: justify;">
                      ${filteredContent.replace('"', "")}
                  </div>
              </div>
          `;
        return html;
    }

    if (loading) {
        return <Loading containerHeight={'28px'} containerWidth={'100%'} imgHeight={'auto'} imgWidth={'16px'} />
    }

    if (showReview) {
        return <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} ><Text color="#000000" fontSize="16px" lineHeight="20px" ><Linkify>{parseHTML(message?.html)}</Linkify></Text>
            <BotButton onClick={() => reviewHandler(message)}>
                {message.attributes?.task?.type === 'OPEN_SHIFT' ? 'Review Open Shift'
                    : message?.attributes?.task?.type === 'SWAP_SHIFT' ? 'Review Swap Shift'
                        : message?.attributes?.task?.type === 'RELEASE_SHIFT' ? 'Review Release Shift'
                            : message?.attributes?.task?.type === 'HR_INCIDENT_FORM' ? 'Review Your HR Form'
                                : message?.attributes?.case === 'SWAP_SHIFT_REQUEST' ? 'Approve Swap Shift'
                                    : 'Review'}</BotButton></div>;
    }

    if (isPopup) {
        return <>
            {showPopup && <ModalWindow display={"list-item"} width={"515px"} title={"Driver List"} cancelModal={() => { cancelModal() }}>
                {driverList.map((driver, index) => {
                    if (typeof driver === "string") {
                        return (
                            <Container key={index} simpleList={true}>
                                <DriverImage>
                                    <Icon.Person size="24px" color="#516F90" />
                                </DriverImage>
                                <NameAndRole>
                                    <div>{driver}</div>
                                </NameAndRole>
                            </Container>
                        )
                    } else {
                        return (
                            <Container key={index}>
                                {(driver['profileImage'] && driver['profileImage'] != null && driver['profileImage'] != '') ? (
                                    <DriverImage>
                                        <img src={driver['profileImage']} alt={"channel"} />
                                    </DriverImage>
                                )
                                    :
                                    (
                                        <DriverImage>
                                            <Icon.Person size="24px" color="#516F90" />
                                        </DriverImage>
                                    )}
                                <NameAndRole>
                                    <div>{driver['friendlyName']}</div>
                                    <div className={"role"}>{driver['role']}</div>
                                </NameAndRole>
                            </Container>
                        )
                    }
                })}
            </ModalWindow>}
            {popupText}
            <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} >
                <BotButton onClick={() => { getDriverList() }}> Show Driver List </BotButton>
                <BotButton onClick={deleteMessageAndCompleteTask}>Clear Message</BotButton>
            </div>
        </>;
    }

    if (showBadWordsType) {
        const newMessage = message?.attributes?.message?.match(/Unfiltered content:([\s\S]*?)Filtered content:/)
            ? message?.attributes?.message
            : 'Unfiltered content:\n' + message?.attributes?.message + '\nFiltered content:\n' + filter?.clean(message?.attributes?.message)
        let messageConvertHtml = badWordsHighlight(newMessage);
        return (
            <>
                <Text color="#000000" fontSize="16px" lineHeight="20px" ><Linkify> {parseHTML(message?.html)}</Linkify></Text>
                {showBadMessage && <Text width={'94%'} margin={'35px 5px 5px 25px'}>{parseHTML(messageConvertHtml)}</Text>}
                {/* <BotShowBadMessage identity={identity} text={attributes.message}/> */}
                <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} >
                    <BotButton onClick={showBadMessageHandler}> {showBadMessage ? 'Hide message' : 'Show message'}</BotButton>
                    <BotButton onClick={deleteMessageAndCompleteTask}>Clear Message</BotButton>
                </div>
            </>
        );
    }

    if (showIncidentForm) {
        return (
            <>
                <Text color="#000000" fontSize="16px" lineHeight="20px" ><Linkify>{parseHTML(message?.html)}</Linkify></Text>
                <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} >
                    {task && task.hrFormIncidentId &&
                        <BotButton onClick={hrIncidentFormClickHandler}>
                            Review Your HR Form
                        </BotButton>}
                    <BotButton onClick={deleteMessageAndCompleteTask}>
                        Complete
                    </BotButton>
                </div>
            </>
        );
    }


    if (showNotificationType) {
        return (
            <>
                <Text color="#000000" fontSize="16px" lineHeight="20px" >
                    {message?.html.includes('AI Inspection FAILED!') ?
                        <Linkify option={{ 'target': '_blank' }}>
                            {parseHTML(message?.html.replace(/(<a\b[^>]*>)(.*?)(<\/a>)/ig, '$1view link$3').replace(/(<a\b[^>]*")(.*?)(>)/ig, '$1 target="_blank"$2$3'))}
                        </Linkify>
                        :
                        <Linkify option={{ 'target': '_blank' }}>
                            {parseHTML(message?.html)}
                        </Linkify>
                    }
                </Text>
                <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} >
                    <BotButton onClick={deleteMessageAndCompleteTask}>
                        Clear Message
                    </BotButton>
                </div>
            </>
        );
    }

    if (showUnknownNotificationType) {
        return (
            <>
                <Text color="#000000" fontSize="16px" lineHeight="20px" ><Linkify>{parseHTML(message?.html)}</Linkify></Text>
                <div style={{ marginTop: '5px', display: 'flex', justifyContent: 'center', flexWrap: 'wrap' }} >
                    <BotButton onClick={deleteMessage}>
                        Clear Message*
                    </BotButton>
                </div>
            </>
        );
    }

    return <></>;
}

export default BotTaskButtons